

.ace-main{
  position: relative;
  line-height: calc(14px + 2px);
  padding-left: 50px;
  background: white;
  color: black;
  font-size: 14px !important;
  font-family: 'Lucida Console', monospace;
  background-clip: content-box;
  height: 100%;
  overflow: auto;
  line-height: inherit;
}

.left-div{
  line-height: calc(14px + 2px);
  background: white;
  color: black;
  font-size: 14px !important;
  font-family: 'Lucida Console', monospace;
  height: 600px;
  overflow: auto;
  line-height: inherit; 
  width: 100%;
}

.ace-body{
  /* margin-left: 6px;
  border-left: solid 1.5px black;
  border-left-style: dashed;  */
}

.ace-button {
  color: black;
  position: absolute;
  left: 37px;
  translate: 0 -14px;
  background: inherit;
  border: none;
  padding: 0;
  outline: inherit;
  overflow: hidden;
  font-size: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.ace-line{
  position: absolute;
  left: 30px;
  color: white;
  font-size: calc(14px - 1px);
  translate: -100% 1px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.ace_search.right {
  position: relative;
  border-radius: 0px 0px 0px 5px;
  border-right: 0 none;
  right: 0;
}

.ace_searchbtn{
  padding: 8px;
  font-size: 16px;
  border: solid 1px;
  color: gray;
}

.search_btn{
  text-align: center;
  padding: 0px;
  background-color: #8080809c;
  color: #00000091;
  border: solid 1px #0000008a;
  cursor: pointer;
}

.dot_image{
  border-right: solid 1px black;
  border-right-style: dashed;
  padding: 2px 0px;
}

.formatter_btn{
  color : white;
  background-color: darkcyan;
}

.formatter_btn:hover{
  color : darkcyan;
  background-color: white;
}

.navbar_btn{
  color : white;
}

.navbar_btn:hover{
  color : darkgrey;
}

